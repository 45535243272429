import React from "react"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
const WisdomApplicationPage = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang={language}
          title="Apply Here For The Wisdom For Wisdom Program"
          description="Follow the Wisdom For Wisdom steps to apply"
        />

        <div className="joshua-tree-content w4w__application">
          <div className="columns has-text-centered">
            <div className="column">
              <h1 style={{ marginBottom: 0 }}>Wisdom For Wisdom Application</h1>
            </div>
          </div>

          <section className="body-section">
            <div className="columns">
              <div className="column is-6"></div>
              <div className="column">
                <div className="application-wrapper">
                  <Seamless src="https://secureform.seamlessdocs.com/f/fab501d6862e37c65ab077991d7bdaef?embedded=true" />
                </div>
              </div>
              <div className="column is-6"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default WisdomApplicationPage
